'use client'
import { PlaceContext, type TPlaceContext } from '@lib/analytics'
import type { ReactNode } from 'react'

export interface PlaceAnalyticsWrapperWrapperProps {
  readonly value: TPlaceContext
  readonly children: ReactNode
}

export default function PlaceAnalyticsWrapper({
  value,
  children,
}: PlaceAnalyticsWrapperWrapperProps) {
  return <PlaceContext.Provider value={value}>{children}</PlaceContext.Provider>
}
